<template>
  <q-layout view="hHh LpR fFf">
    <q-header class="background_gradient text-white">
      <q-toolbar class="q-pl-md">
        <q-toolbar-title
          style="flex: none; margin-left: 0px"
          :class="isInCourse && !isWideScreen ? '' : ''"
        >
          <div class="cursor-pointer" @click="router.push({ name: 'Course' })">
            <IlectLogo />
          </div>
        </q-toolbar-title>

        <q-tabs stretch no-caps align="left" inline-label>
          <q-route-tab
            v-for="menuItem in topMenuList"
            :key="menuItem.path"
            :to="menuItem.path"
            :icon="menuItem.icon"
            :label="menuItem.label"
            :class="{ 'q-tab--active': $route.path.includes(menuItem.path) }"
          />
        </q-tabs>
        <q-space />

        <LangSwitcher />

        <q-btn-dropdown icon="person" flat round dense>
          <q-list>
            <q-item v-close-popup clickable :to="'/profile'">
              <q-item-section avatar>
                <q-avatar icon="badge" />
              </q-item-section>
              <q-item-section>
                {{ $t("components.header.profile") }}
              </q-item-section>
            </q-item>

            <q-item v-close-popup clickable :to="'/about'">
              <q-item-section avatar>
                <q-avatar icon="info" />
              </q-item-section>
              <q-item-section>
                {{ $t("components.header.about") }}
              </q-item-section>
            </q-item>

            <q-item v-close-popup clickable :to="'/support'">
              <q-item-section avatar>
                <q-avatar icon="contact_support" />
              </q-item-section>
              <q-item-section>
                {{ $t("components.header.support") }}
              </q-item-section>
            </q-item>

            <q-item v-close-popup clickable @click="logout">
              <q-item-section avatar>
                <q-avatar icon="logout" />
              </q-item-section>
              <q-item-section>
                {{ $t("common.logout") }}
              </q-item-section>
            </q-item>
          </q-list>
        </q-btn-dropdown>
      </q-toolbar>
    </q-header>

    <q-drawer
      v-if="sideMenuList.length"
      v-model="drawer"
      show-if-above
      mini-to-overlay
      :mini="miniState"
      class="background_gradient_vertical text-white div--shadow"
      :breakpoint="500"
      bordered
      :width="200"
      @mouseover="miniState = false"
      @mouseout="miniState = true"
    >
      <q-list clickable class="menu-list">
        <q-item
          v-for="menuItem in sideMenuList"
          :key="menuItem.path"
          v-ripple
          :class="{
            'q-router-link--active': $route.path.includes(menuItem.path),
            'text-bold': true,
          }"
          padding
          :to="menuItem.path"
        >
          <q-item-section avatar no-shadow>
            <q-btn dense round flat :icon="menuItem.icon" no-shadow>
              <q-badge
                v-if="menuItem.badge"
                :label="menuItem.badge"
                floating
                color="red"
              ></q-badge>
            </q-btn>
          </q-item-section>

          <q-item-section>
            {{ menuItem.label }}
          </q-item-section>
          <div class="right-border"></div>
        </q-item>
      </q-list>
    </q-drawer>

    <q-page-container>
      <Breadcrumb />
      <router-view v-slot="{ Component }">
        <!--        Keep alive for ForumView is to preserve search result-->
        <keep-alive include="ForumView">
          <component :is="Component" />
        </keep-alive>
      </router-view>
    </q-page-container>
  </q-layout>
</template>

<script>
import { openURL, useQuasar } from "quasar";
import { useRoute, useRouter } from "vue-router";
import { computed, onMounted, ref, watch } from "vue";
import { AuthModule } from "@/store/modules/auth";
import axios from "axios";
import {
  courseApi,
  forumApi,
  assessmentAnalysisApi,
} from "@/api/ilect-openapi-configuration";
import Breadcrumb from "@/components/Breadcrumb.vue";
import IlectLogo from "@/components/IlectLogo.vue";
import LangSwitcher from "@/components/LangSwitcher.vue";
import store from "@/store";
import { useI18n } from "vue-i18n";
import { useNotificationsStore } from "@/store/modules/notifications";

export default {
  components: { IlectLogo, Breadcrumb, LangSwitcher },
  store,
  setup() {
    const $q = useQuasar();
    const $route = useRoute();
    const $t = useI18n().t;
    const router = useRouter();
    const isInCourse = ref(!!$route.params.course_id);
    const isWideScreen = ref($q.screen.width > 1023);

    const miniState = ref(true);
    const drawer = ref(false);

    const courseName = ref("");
    const course = ref({});
    const assignment = ref({});
    const dataset = ref({});
    const forum = ref({});
    const { locale } = useI18n();

    const topMenuList = ref([]);
    const sideMenuList = ref([]);

    const isStaff = computed(() => {
      return AuthModule.is_staff;
    });
    const isGuest = computed(() => {
      return AuthModule.roles?.includes("guest");
    });
    const isLoggedIn = computed(() => {
      return AuthModule.isAuthenticated;
    });
    // Default is false,
    $q.dark.set(AuthModule.isDarkmode);

    if (AuthModule.isDarkmode) {
      AuthModule.toggleDarkmode();
    }

    watch(
      () => AuthModule.isAuthenticated,
      () => {
        isLoggedIn.value = AuthModule.isAuthenticated;
        setMenuList();
      },
    );
    watch(
      () => $route.params.course_id,
      () => setMenuList(),
    );
    watch(
      () => $q.screen.width,
      () => (isWideScreen.value = $q.screen.width > 1023),
    );
    watch(
      () => $route.name,
      () => {
        course.value = {};
        assignment.value = {};
        dataset.value = {};
      },
    );

    watch(locale, () => {
      setMenuList();
    });

    const notificationsStore = useNotificationsStore();
    const courseId = ref($route.params.course_id);
    const timeoutId = ref(null);

    onMounted(() => {
      timeoutId.value = setTimeout(() => {
        if (courseId.value) {
          notificationsStore.fetchForumStats(courseId.value);
        }
        clearTimeout(timeoutId.value);
      }, 0);
    });

    watch(
      () => $route.params.course_id,
      (newCourseId) => {
        timeoutId.value = setTimeout(() => {
          console.log(newCourseId, "newCourseId");
          if (newCourseId) {
            courseId.value = newCourseId;
            notificationsStore.fetchForumStats(courseId.value);
          }
          clearTimeout(timeoutId.value);
        }, 0);
      },
    );

    watch(
      () => $route.name,
      (newCourseId) => {
        timeoutId.value = setTimeout(() => {
          if (newCourseId) {
            notificationsStore.fetchForumStats(courseId.value);
          }
          clearTimeout(timeoutId.value);
        }, 0);
      },
    );

    watch(
      () => notificationsStore.forumStats,
      (newCount) => {
        timeoutId.value = setTimeout(() => {
          if (newCount > 0) {
            const forumMenu = sideMenuList.value.find((item) =>
              item.path.includes("/forum"),
            );
            if (forumMenu) {
              forumMenu.badge = newCount;
            }
          }
          clearTimeout(timeoutId.value);
        }, 0);
      },
    );

    const forum_unread_total_count = computed(
      () => notificationsStore.getUnreadCount().unread_total_count,
    );

    const toggleDarkmode = () => {
      AuthModule.toggleDarkmode();
      $q.dark.set(AuthModule.isDarkmode);
    };

    const setMenuList = () => {
      isInCourse.value = !!$route.params.course_id;

      const upperMenuList = [
        {
          path: "/course",
          label: $t("components.header.course"),
          roleCheck: true,
        },
        {
          path: "/ilect-forum",
          label: $t("components.header.ilectForum"),
          roleCheck: true,
        },
        {
          path: "/pygrade/",
          label: "Pygrade",
          roleCheck: true,
        },
        {
          path: "/testtype",
          label: $t("components.header.testType"),
          roleCheck: isStaff.value,
        },
      ];

      let lowerMenuList = [
        {
          icon: "fas fa-solid fa-calendar-day",
          path: `/course/${$route.params.course_id}/calendar`,
          label: $t("components.sidemenu.calendar"),
          roleCheck: isGuest.value || isStaff.value,
        },
        {
          icon: "assignment",
          path: `/course/${$route.params.course_id}/assignment`,
          label: $t("components.sidemenu.assignment"),
          roleCheck: isGuest.value || isStaff.value,
        },
        {
          icon: "dns",
          path: `/course/${$route.params.course_id}/dataset`,
          label: $t("components.sidemenu.dataset"),
          roleCheck: isGuest.value || isStaff.value,
        },
        {
          icon: "ballot",
          path: `/course/${$route.params.course_id}/submission`,
          label: $t("components.sidemenu.submission"),
          roleCheck: isGuest.value || isStaff.value,
        },
        {
          icon: "textsms",
          path: `/course/${$route.params.course_id}/forum`,
          label: $t("components.sidemenu.forum"),
          roleCheck: isGuest.value || isStaff.value,
          badge: forum_unread_total_count,
        },
        {
          icon: "analytics",
          path: `/course/${$route.params.course_id}/assessment`,
          label: $t("components.sidemenu.assessment"),
          roleCheck: isGuest.value || isStaff.value,
        },
        {
          icon: "settings",
          path: `/course/${$route.params.course_id}/settings`,
          label: $t("components.sidemenu.settings"),
          roleCheck: isStaff.value,
        },
      ];

      topMenuList.value = [];
      sideMenuList.value = [];

      for (let row of upperMenuList) {
        if (row.roleCheck) {
          topMenuList.value.push(row);
        }
      }

      // In a course
      if ($route.params.course_id) {
        courseApi.courseRetrieve($route.params.course_id).then((res) => {
          course.value = res.data;
          courseName.value = res.data.title;

          if (course.value.contain_videos === true) {
            sideMenuList.value = [
              {
                icon: "play_circle",
                path: `/course/${$route.params.course_id}/video`,
                label: $t("components.sidemenu.video"),
                roleCheck: true,
              },
              ...sideMenuList.value,
            ];
            lowerMenuList = lowerMenuList.filter((ele) => {
              return ele.label !== $t("components.sidemenu.video");
            });
          }

          if (res.data.workbook_config) {
            sideMenuList.value = [
              {
                icon: "fas fa-solid fa-book",
                path: `/course/${$route.params.course_id}/workbook`,
                label: $t("components.sidemenu.workbook"),
                roleCheck: true,
              },
              ...sideMenuList.value,
            ];
          } else {
            sideMenuList.value = [
              {
                icon: "fab fa-google-drive",
                path: `/course/${$route.params.course_id}/colab`,
                label: $t("components.sidemenu.googleColab"),
                roleCheck: true,
              },
              ...sideMenuList.value,
            ];
          }
        });

        // check if assessment is available
        const populateMenu = async () => {
          console.log("vefore popular", lowerMenuList);
          await assessmentAnalysisApi
            .assessmentAnalysisExistenceCourseRetrieve($route.params.course_id)
            .then((res) => {
              if (!res.data) {
                lowerMenuList = lowerMenuList.filter((ele) => {
                  return ele.label !== $t("components.sidemenu.assessment");
                });
              }
            })
            .catch((err) => {
              console.error(err);
            });

          //if populate side-menu from filtered data
          for (let row of lowerMenuList) {
            if (row.roleCheck) {
              sideMenuList.value.push(row);
            }
          }
        };
        populateMenu();
      }

      // In assignment
      if (
        $route.name === "AssignmentDetail" ||
        $route.name === "TestCase" ||
        $route.name === "Dataset"
      ) {
        axios
          .get(`/api/Assignment/${$route.params.assignment_id}`)
          .then((res) => {
            assignment.value = res.data;
          })
          .catch(() => {
            // Dataset only
            if (
              Object.keys(assignment.value).length === 0 &&
              assignment.value.constructor === Object
            ) {
              axios
                .get(`/api/DatasetGroup/`, {
                  params: {
                    course: $route.params.course_id,
                  },
                })
                .then((res) => {
                  dataset.value = res.data.results.filter((dataset) => {
                    return dataset.id === $route.params.assignment_id;
                  })[0];
                });
            }
          });
      }

      // In forum
      if ($route.name === "ForumDetailInCourse") {
        forumApi
          .forumRecordRetrieve($route.params.forum_record_id)
          .then((res) => {
            forum.value = res.data;
          });
      }
    };

    const logout = () => {
      $q.dialog({
        title: $t("common.confirm"),
        message: "Are you sure you want to logout?",
        cancel: true,
      }).onOk(() => {
        router.push({ name: "Logout" });
      });
    };

    onMounted(() => {
      setMenuList();
    });
    const dark = ref(AuthModule.isDarkmode);
    return {
      logout,
      isLoggedIn,
      isInCourse,
      isWideScreen,
      topMenuList,
      sideMenuList,
      toggleDarkmode,
      $route,
      router,
      courseName,
      course,
      assignment,
      dataset,
      forum,
      openURL,
      miniState,
      dark,
      drawer,
    };
  },
};
</script>
