import axios from "axios";
import {
  Action,
  getModule,
  Module,
  Mutation,
  VuexModule,
} from "vuex-module-decorators";
import store from "@/store";
import { LoginResponse } from "vue-gapi";
import { GoogleDriveManager } from "@/utils/google-drive";
import { TokenRefresh } from "@/openapi-ts";
import { authApi } from "@/api/ilect-openapi-configuration";
import { RouteLocationNormalized } from "vue-router";

export interface AuthState {
  authToken: string;
  refreshToken: string;
  emailAddress: string;
  callback: string | RouteLocationNormalized;
}

/*
 * Note that in Mutations, you cannot use setters.
 * Directly edit the attributes to modify the state.
 * */
@Module({
  dynamic: true,
  store: store,
  namespaced: true,
  name: "auth",
  preserveState: localStorage.getItem("vuex") !== null,
})
export default class AuthenticationModule
  extends VuexModule
  implements AuthState
{
  public authToken = "";
  public refreshToken = "";
  public emailAddress = "";
  public callback: string | RouteLocationNormalized = "";
  public userId: string = "";
  public is_staff = false;
  public roles = ["no_role"];
  public editorCourses: string[] = [];
  public isDarkmode = false;
  public displayLang = "ja";
  public forumActiveTab = "announcement";

  @Mutation
  private setAuthToken(token: string) {
    this.authToken = token;
  }

  @Mutation
  private setRefreshToken(token: string) {
    this.refreshToken = token;
  }

  @Mutation
  private setEmailAddress(emailAddress: string) {
    this.emailAddress = emailAddress;
  }

  @Mutation
  private setUserId(id: string) {
    this.userId = id;
  }

  @Mutation
  private setIsStaff(is_staff: boolean) {
    this.is_staff = is_staff;
  }

  @Mutation
  public setCallback(callback: string | RouteLocationNormalized) {
    this.callback = callback;
  }

  @Mutation
  public setRoles(roles: Array<string>) {
    this.roles = roles;
  }

  @Mutation
  public setEditorCourses(editorCourses: Array<string>) {
    this.editorCourses = editorCourses;
  }

  @Mutation public toggleDarkmode(): void {
    this.isDarkmode = !this.isDarkmode;
  }

  @Mutation public setDisplayLang(lang: string): void {
    this.displayLang = lang;
  }

  @Mutation
  public setForumActiveTab(tab: string) {
    this.forumActiveTab = tab;
  }

  // This function is for logout.
  @Mutation
  resetToken() {
    this.emailAddress = "";
    this.userId = "";
    this.authToken = "";
    this.refreshToken = "";
    this.roles = [];
    this.editorCourses = [];
    this.is_staff = false;
  }

  @Action registerWithEmail(context: TokenRefresh) {
    return new Promise((resolve) => {
      this.setAuthToken(context.access);
      this.setRefreshToken(context.refresh);
      resolve(resolve);
    });
  }

  @Action loginWithGoogle(context: any) {
    return new Promise((resolve, reject) => {
      context.gapi.login().then((response: LoginResponse) => {
        const { access_token } = response.currentUser.getAuthResponse();
        this.obtainTokenWithGoogle({ access_token }).then(() => {
          context.gapi.getGapiClient().then(() => {
            const gdriveManager: GoogleDriveManager = new GoogleDriveManager(
              context.gapi,
            );
            gdriveManager
              .getGapiClient()
              .then(() => {
                gdriveManager
                  .createGoogleDriveRootDirIfNotExists()
                  .then((res) => {
                    resolve(res);
                  });
              })
              .catch((res: any) => {
                console.log({ res });
                reject(res);
              });
          });
        });
      });
    });
  }

  @Action
  async obtainTokenWithGoogle(data: any) {
    return new Promise((resolve, reject) => {
      axios
        .post("/api/Auth/SocialLogin/Google/", data)
        .then((response) => {
          this.setAuthToken(response.data.access);
          this.setRefreshToken(response.data.refresh);
          this.setEmailAddress(response.data.user.email);
          this.setUserId(response.data.user.pk);
          resolve(response);
        })
        .catch((error) => {
          this.setAuthToken("");
          this.setRefreshToken("");
          reject(error);
        });
    });
  }

  // This function is for refreshing access token.
  @Action
  refreshCurrentToken() {
    return new Promise((resolve, reject) => {
      if (this.isAuthenticated && this.refreshToken) {
        // Axios interceptors rely on "refresh" to determine if it is a refresh token request
        // use axios instance for request relating to authentication so that it not uses the axios global interceptors(defined in main.ts)
        // this will prevent in infinite loop
        const axiosAuth = axios.create({
          baseURL: import.meta.env.VITE_APP_BACKEND_API_ENDPOINT,
          headers: {
            Authorization: `Bearer ${this.authToken}`,
            "Content-Type": "application/json;charset=utf-8",
          },
        });
        axiosAuth
          .post("/api/Auth/Token/Refresh/", { refresh: this.refreshToken })
          .then((response) => {
            this.setAuthToken(response.data.access);
            this.setRefreshToken(response.data.refresh);
            resolve(response);
          })
          .catch((error) => {
            reject(error);
          });
      } else {
        reject();
      }
    });
  }

  @Action getGoogleDriveRootDirId() {
    return new Promise((resolve, reject) => {
      axios
        .get(`/api/UsergoogleDriveId/root/`)
        .then((res: any) => {
          resolve(res.data.id);
        })
        .catch((error) => {
          reject(error);
        });
    });
  }

  @Action postLogin() {
    return new Promise((resolve, reject) => {
      authApi
        .authUserInfoRetrieve()
        .then((res) => {
          AuthModule.setUserId(res.data.id);
          AuthModule.setRoles(res.data.roles);
          AuthModule.setIsStaff(res.data.is_staff);
          AuthModule.setEditorCourses(res.data.editor_courses);
          resolve(res);
        })
        .catch((e) => {
          reject(e);
        });
    });
  }

  public get isAuthenticated() {
    // Explicitly convert to bool
    return !!this.authToken;
  }

  public get isStaff() {
    return () => this.is_staff;
  }

  public get isEditor() {
    return (courseId: string) => this.editorCourses.includes(courseId);
  }

  public get getDarkMode() {
    return () => this.isDarkmode;
  }

  public get getDisplayLang() {
    return this.displayLang;
  }

  public get getForumActiveTab() {
    return this.forumActiveTab;
  }
}

export const AuthModule = getModule(AuthenticationModule);
